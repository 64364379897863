import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import parseCurrency, { sanitizeCurrencyValue } from 'utility/parseCurrency';
import parsePercent, { normalizePercentValue } from 'utility/parsePercent';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import parseNumeric from 'utility/parseNumeric';
import parseNumber from 'utility/parseNumber';
import parseDecimal, { normalizeDecimalValue } from 'utility/parseDecimal';
import validateEmail from 'utility/validateEmail';
import parseAlphanum from 'utility/parseAlphanum';
import CopyToClipboard from 'components/CopyToClipboard';
import { isNotNullAndNotUndefined } from 'utility/validation';

interface CustomErrorMessages{
  required?: string;
  min?: string;
  max?: string;
}

interface InputProps {
  disabled?: boolean;
  name: string;
  type?: 'email' | 'password' | 'text' | 'number' | 'currency' | 'percent' | 'decimal' | 'numeric' | 'alphanum';
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  label?: React.ReactNode;
  labelIcon?: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  spacing?: number
  onChange?: (...args) => void;
  // Use this prop if you want to control this input's Value from Parent Component
  isControlled?: boolean;
  valueControlled?: string | number | readonly string[];
  visible?: boolean;
  showInlineError?: boolean;
  min?: number;
  max?: number;
  errorMessages?: CustomErrorMessages
  readOnly?: boolean;
  rightIcon?: React.ReactNode;
  isHighlighted?: boolean;
  highlightColor?: 'yellow' | 'blue' | 'green' | 'red';
  validationMode?: 'onBlur' | 'onSubmit'
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
  copyToClipboard?: boolean
}

const highlightStyle = {
  yellow: 'bg-yellow-200/80',
  blue: 'bg-blue-200/80',
  green: 'bg-green-200/80',
  red: 'bg-red-200/80',
};

function Input(props: InputProps) {
  const {
    name, type, required, maxLength, minLength, label, disabled, wrap, size, visible,
    alignItems, spacing, onChange, isControlled, valueControlled, showInlineError, min, max, errorMessages,
    readOnly, isHighlighted, highlightColor, validationMode, onBlur, rightIcon, copyToClipboard, labelIcon, ...rest
  } = props;
  const {
    register, formState: { errors }, setValue, trigger,
  } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';
  const className = `
    p-1 border text-${size} border-gray-200 w-full focus:outline-none placeholder:text-gray-300 
    ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle} ${isHighlighted ? highlightStyle[highlightColor] : ''}
  `;

  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  const inputWithRightIconWidth = () => {
    if (rightIcon && copyToClipboard) return 10;
    if (rightIcon || copyToClipboard) return 11;
    return 12;
  };

  const handleChange = (e) => {
    let val;
    if (type === 'currency') {
      val = sanitizeCurrencyValue(e.target.value).toString();
    } else if (type === 'decimal') {
      val = normalizeDecimalValue(e.target.value).toString();
    } else if (type === 'percent') {
      val = normalizePercentValue(e.target.value).toString();
    } else if (type === 'numeric') {
      val = parseNumeric(e.target.value).toString();
    } else if (type === 'alphanum') {
      val = parseAlphanum(e.target.value).toString();
    } else if (type === 'number') {
      val = parseNumber(e.target.value).toString();
    } else {
      val = e.target.value;
    }
    if (maxLength) {
      setValue(name, val.slice(0, maxLength));
    } else {
      setValue(name, val);
    }
    return onChange(e);
  };

  const handleOnBlur = (e) => {
    if (validationMode === 'onBlur' || showInlineError) { trigger(name); }
    return onBlur(e);
  };

  useEffect(() => {
    if (valueControlled && isControlled) {
      setValue(name, valueControlled);
    }
  }, [valueControlled]);

  const errorMessage = () => {
    if (get(errors, name).type === 'min') {
      if (errorMessages?.min) return errorMessages.min;
      return `Minimum value of '${label}' is ${min}`;
    }
    if (get(errors, name).type === 'max') {
      if (errorMessages?.max) return errorMessages.max;
      return `Maximum value of '${label}' is ${max}`;
    }
    if (get(errors, name).type === 'required') {
      if (errorMessages?.required) return errorMessages.required;
      return get(errors, name)?.message?.toString();
    }
    return get(errors, name).message?.toString();
  };

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      {label && (
        <Grid item xs={wrap ? 4 : 12} className="flex items-center">
          <span className={`text-${size}`}>{label}</span>
          {required && !disabled && <span className="text-red-600">*</span>}
          {Boolean(labelIcon) && labelIcon}
        </Grid>
      )}
      <Grid item xs={inputWidth()}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={inputWithRightIconWidth()}>
            {type === 'currency' && (
            // Currency must initialized by change format on populate
            // ex: InstallmentEditForm.populateInstallment
            // Input need to be sanitized first before send to API
            // ex: IntallmentEditForm.handleSubmit
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    mustPositive: (v) => (required ? sanitizeCurrencyValue(v) >= 0 : true),
                    min: (v) => (min ? sanitizeCurrencyValue(v) >= min : true),
                    max: (v) => (max ? sanitizeCurrencyValue(v) <= max : true),
                  },
                })}
                {...rest}
                autoComplete="off"
                type="text"
                className={className}
                disabled={disabled}
                value={isControlled ? parseCurrency(valueControlled) : parseCurrency(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'percent' && (
            // Percent must initialized by change format on populate
            // ex: InstallmentEditForm.populateInstallment
            // Input DON'T need to be sanitized before send to API
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true),
                    min: (v) => (isNotNullAndNotUndefined(min) ? normalizePercentValue(v) >= min : true),
                    max: (v) => (isNotNullAndNotUndefined(max) ? normalizePercentValue(v) <= max : true),
                  },
                })}
                {...rest}
                autoComplete="off"
                type="text"
                className={className}
                disabled={disabled}
                value={isControlled ? parsePercent(valueControlled) : parsePercent(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'numeric' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true),
                    isNumeric: (v) => (required ? parseNumeric(v).length > 0 : true)
                    ,
                  },
                })}
                {...rest}
                autoComplete="off"
                type="tel"
                className={className}
                disabled={disabled}
                value={isControlled ? parseNumeric(valueControlled) : parseNumeric(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'number' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true),
                    min: (v) => (isNotNullAndNotUndefined(min) ? parseInt(v, 10) >= min : true),
                    max: (v) => (isNotNullAndNotUndefined(max) ? parseInt(v, 10) <= max : true),
                  },
                })}
                {...rest}
                autoComplete="off"
                type="tel"
                className={className}
                disabled={disabled}
                // this parseNumber utility still buggy
                value={isControlled ? parseNumber(valueControlled) : parseNumber(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'decimal' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true),
                    min: (v) => (isNotNullAndNotUndefined(min) ? normalizeDecimalValue(v) >= min : true),
                    max: (v) => (isNotNullAndNotUndefined(max) ? normalizeDecimalValue(v) <= max : true),
                  },
                })}
                {...rest}
                autoComplete="off"
                type="text"
                className={className}
                disabled={disabled}
                value={isControlled ? parseDecimal(valueControlled) : parseDecimal(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'email' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true),
                    validate: (val: string) => {
                      if (!val) {
                        return true;
                      }
                      if (validateEmail(val)) {
                        return true;
                      }
                      return 'Email Tidak Valid';
                    },
                  },
                })}
                {...rest}
                autoComplete="off"
                type="email"
                className={className}
                disabled={disabled}
                value={isControlled ? valueControlled : (value || '')}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type === 'alphanum' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {
                    noWhiteSpace: (v) => (required ? !!v.toString().trim() : true)
                    ,
                  },
                })}
                {...rest}
                autoComplete="off"
                type="text"
                className={className}
                disabled={disabled}
                value={isControlled ? parseAlphanum(valueControlled) : parseAlphanum(value)}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
            {type !== 'currency' && type !== 'percent' && type !== 'numeric'
            && type !== 'number' && type !== 'decimal' && type !== 'email' && type !== 'alphanum' && (
              <input
                {...register(name, {
                  required: required ? `Field '${label}' is required` : false,
                  maxLength,
                  minLength: {value: minLength, message: `Minimum length of '${label}' is ${minLength} characters`},
                  onChange: handleChange,
                  validate: {noWhiteSpace: (v) => (required ? !!v.toString().trim() : true)},
                })}
                {...rest}
                autoComplete="off"
                type={type}
                className={className}
                disabled={disabled}
                value={isControlled ? valueControlled : (value || '')}
                readOnly={readOnly}
                onBlur={handleOnBlur}
              />
            )}
          </Grid>
          {
            (!!rightIcon || copyToClipboard) && (
              <Grid item xs={rightIcon && copyToClipboard ? 2 : 1} alignItems="center" justifyContent="center" textAlign="center" className="pt-1">
                <div className="flex justify-evenly">
                  {copyToClipboard && <CopyToClipboard text={value} />}
                  {rightIcon}
                </div>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      {showInlineError && get(errors, name) && (
        <div className={`flex w-full justify-end text-red-600 text-${size} mb-${marginBottomSize[size]} mt-0.5`}>{errorMessage()}</div>
      )}
    </Grid>
  );
}

Input.defaultProps = {
  disabled: false,
  type: 'text',
  placeholder: '',
  required: false,
  maxLength: undefined,
  minLength: 0,
  size: 'xs',
  wrap: true,
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  label: null,
  isControlled: false,
  valueControlled: undefined,
  visible: true,
  showInlineError: false,
  min: undefined,
  max: undefined,
  errorMessages: undefined,
  readOnly: false,
  rightIcon: undefined,
  isHighlighted: false,
  highlightColor: 'red',
  validationMode: 'onSubmit',
  onBlur: () => null,
  copyToClipboard: false,
  labelIcon: undefined,
};

export default Input;

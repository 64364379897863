/**
 * Checks if a value is not null and not undefined.
 */
export const isNotNullAndNotUndefined = (value: unknown): boolean => value !== null && value !== undefined;

/**
 * Checks if a value is a non-empty string.
 */
export const isNotEmptyString = (value: unknown): boolean => typeof value === 'string' && value.trim().length > 0;

/**
 * Checks if an object has a specific key.
 */
export const hasKey = <T extends object>(obj: T, key: keyof any): key is keyof T => Object.prototype.hasOwnProperty.call(obj, key);

/**
 * Checks if an array is not empty.
 */
export const isNotEmptyArray = (value: unknown): boolean => Array.isArray(value) && value.length > 0;

import React, { forwardRef, LegacyRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import { classNames } from 'utility/classNames';

interface OptionProps {
  name: string | number;
  value: string | number;
  key?: string | number
}

interface SelectProps {
  disabled?: boolean;
  name: string;
  type?: 'text' | 'number';
  placeholder?: string;
  required?: boolean;
  label?: string;
  options: OptionProps[];
  onChange?: (...args) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  wrap?: boolean;
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline';
  spacing?: number;
  visible?: boolean;
  isLoading?: boolean;
  showInlineError?: boolean
  isFocus?: boolean;
  readOnly?: boolean;
  showAlert?: boolean;
  alertText?: React.ReactNode;
  labelIcon?: React.ReactNode;
}

const Select = forwardRef((props: SelectProps, ref: LegacyRef<HTMLLabelElement>) => {
  const {
    name, required, label, disabled, options, visible, labelIcon,
    onChange, size, wrap, alignItems, spacing, isLoading, showInlineError, isFocus, readOnly, showAlert, alertText, ...rest
  } = props;
  const {
    register, formState: { errors }, setFocus,
  } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed disabled:text-inherit text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black disabled:text-black bg-gray-50' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : 'focus:border-gray-200 ';
  const emptyValueStyle = (value === null || value === undefined || value === '') ? 'text-gray' : '';

  const handleChange = (e) => onChange(e, name);

  useEffect(() => {
    if (isFocus)setFocus(name);
  }, [isFocus]);

  useEffect(() => {
    if (showAlert)setFocus(name);
  }, [showAlert]);

  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  if (!visible) return null;

  return (
    <div className={classNames(showAlert && 'border-red-600 border-2 mb-2')}>
      <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
        {label && (
          <Grid item xs={wrap ? 4 : 12}>
            <label ref={ref} htmlFor={name} className={`text-${size}`}>{label}</label>
            {required && !disabled && <span className="text-red-600">*</span>}
            {Boolean(labelIcon) && labelIcon}
          </Grid>
        )}
        <Grid item xs={inputWidth()}>
          <div className="relative">
            {isLoading && <div className="absolute animate-pulse h-full w-full bg-gray-200" />}
            <select
              {...register(name, {
                required: required ? `Field '${label}' is required` : false, onChange: handleChange,
              })}
              {...rest}
              // eslint-disable-next-line max-len
              className={`p-1 border text-${size} border-gray-200 w-full focus:ring-0 ${emptyValueStyle} ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}`}
              disabled={readOnly || disabled || isLoading}
              value={value || ''}
            >
              <option className="text-gray" key="" value="">Select</option>
              {options.map((o) => (<option className="text-gray-600" key={o.value} value={o.value}>{o.name}</option>))}
            </select>
          </div>
        </Grid>
        {showInlineError && errors[name] && (
          <Grid item xs={12}>
            <span className={`flex justify-end text-red-600 text-${size} mb-${marginBottomSize[size]}`}>
              {errors[name].message.toString()}
            </span>
          </Grid>
        )}
      </Grid>
      {showAlert && (
        <p className="text-xs text-red-600 text-right mb-2 mr-2">
          {alertText || (
            <span>
              Harap isi field
              <b>
                {' '}
                {label}
                {' '}
              </b>
              dan tekan tombol
              <b> Save </b>
            </span>
          )}
        </p>
      )}
    </div>
  );
});

Select.defaultProps = {
  disabled: false,
  type: 'text',
  placeholder: '',
  required: false,
  onChange: () => null,
  size: 'xs',
  wrap: true,
  alignItems: 'center',
  spacing: null,
  visible: true,
  isLoading: false,
  showInlineError: false,
  isFocus: false,
  label: null,
  readOnly: false,
  showAlert: false,
  alertText: undefined,
  labelIcon: undefined,
};

export default Select;

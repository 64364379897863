import React from 'react';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { heightStyle, marginBottomSize } from 'utility/sizeStyle';
import dayjs from 'dayjs';

interface DatePickerProps {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  alignItems?: 'center' | 'strecth' | 'start' | 'end' | 'baseline'
  spacing?: number
  onChange?: (...args) => void;
  isFuture?: boolean;
  visible?: boolean;
  showInlineError?: boolean
  wrap?: boolean
  readOnly?: boolean;
  minDate?: string;
}

function DatePicker(props: DatePickerProps) {
  const {
    name, required, label, disabled, size, alignItems, spacing, onChange, isFuture, visible, showInlineError, wrap, readOnly,
    minDate,
    ...rest
  } = props;
  const {
    register, formState: { errors }, setError, clearErrors,
  } = useFormContext();
  const value = useWatch({ name });
  const disabledStyle = disabled ? 'bg-gray-50 border-dashed cursor-not-allowed text-inherit' : 'hover:border-gray-500';
  const readOnlyStyle = readOnly ? 'text-black bg-gray-50 active:ring-transparent focus:ring-transparent active:border-gray-200 focus:border-gray-200' : '';
  const errorStyle = get(errors, name) ? 'focus:border-red-600' : '';

  const handleChange = (e) => {
    const date = e.target.value;
    if (!isFuture) {
      if (dayjs(date).isAfter(dayjs())) {
        setError(name, { type: 'required', message: 'Date cannot be greater than today' });
      } else {
        clearErrors(name);
      }
    }
    return onChange(e);
  };

  const inputWidth = () => {
    if (!label) return 12;
    if (wrap) return 8;
    return 12;
  };

  if (!visible) return null;

  return (
    <Grid container spacing={spacing || wrap ? 2 : 1} alignItems={alignItems} mb={marginBottomSize[size]}>
      <Grid item xs={wrap ? 4 : 12}>
        <span className={`text-${size}`}>{label}</span>
        {required && !disabled && <span className="text-red-600">*</span>}
      </Grid>
      <Grid item xs={inputWidth()}>
        <input
          {...register(name, {
            required,
            onChange: handleChange,
            min: minDate ? {
              value: minDate,
              message: `tanggal minimal adalah ${minDate}`,
            } : undefined,
          })}
          {...rest}
          className={`
            p-1 py-0 border text-${size} border-gray-200 w-full focus:border-gray-200 focus:ring-0
            placeholder:text-gray-300 ${heightStyle[size]} ${readOnly ? readOnlyStyle : disabledStyle} ${errorStyle}
          `}
          disabled={disabled}
          type="date"
          value={value || ''}
          readOnly={readOnly}
        />
      </Grid>

      {showInlineError && errors[name] && (
        <Grid item xs={12}>
          <span className={`flex justify-end text-red-600 text-${size} mb-${marginBottomSize[size]}`}>{errors[name].message.toString()}</span>
        </Grid>
      )}
    </Grid>
  );
}

DatePicker.defaultProps = {
  disabled: false,
  placeholder: '',
  required: false,
  size: 'xs',
  alignItems: 'center',
  spacing: null,
  onChange: () => null,
  isFuture: true,
  visible: true,
  wrap: true,
  showInlineError: false,
  readOnly: false,
  minDate: undefined,
};

export default DatePicker;
